<template>
  <div class="download">
    <div class="contain">
      <div class="content">
        <img
          src="@/assets/download/code_iphone.png"
          class="code"
          v-if="isIphone"
        />
        <img src="@/assets/download/code_android.png" class="code" v-else />
        <p class="title">扫描二维码下载【向知APP】</p>
        <div class="icon">
          <img
            src="@/assets/download/iphone_active.png"
            class="icon iphone"
            v-if="isIphone"
          />
          <img
            src="@/assets/download/iphone_normal.png"
            class="icon iphone"
            v-else
            @click="isIphone = true"
          />
          <img
            src="@/assets/download/android_active.png"
            class="icon"
            v-if="!isIphone"
          />
          <img
            src="@/assets/download/android_normal.png"
            class="icon"
            v-else
            @click="isIphone = false"
          />
        </div>
        <!-- <div class="contain">
        <p class="title2">
          *向知APP已获得“公安部信息系统安全等级保护三级认证”和“教育移动互联网应用程序备案”
        </p>
      </div> -->
      </div>
    </div>
    <MyFooter class="footer" />
  </div>
</template>

<script>
export default {
  name: 'Download',
  data () {
    return {
      isIphone: true
    }
  }
}
</script>

<style scoped lang="less">
.download {
  position: relative;
  .content {
    position: fixed;
    text-align: center;
    top: 180px;
    .code {
      width: 162px;
      height: 162px;
    }
    .title {
      font-size: 20px;
      line-height: 22px;
      font-weight: bold;
      padding: 22px 0;
    }
    .icon {
      cursor: pointer;
      img {
        width: 69px;
        height: 24px;
        background-size: contain;
      }
    }
    .iphone {
      margin-right: 19px;
    }
    // .title2 {
    //   width: 531px;
    //   height: 12px;
    //   font-size: 12px;
    //   font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    //   color: #7b7b7b;
    //   line-height: 18px;
    //   margin-top: 12px;
    // }
  }
  .footer {
    position: fixed;
    bottom: 0;
  }
}
</style>
